import styled from "styled-components";
import { PRIMARY_COLOR } from "../../util/constants";

export const HeaderContainer = styled.div`
    width: 100%;
    height: 110px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
    .MuiSvgIcon-root {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const MessengerContainer = styled.div`
    height: calc(100vh - 72px);
    width: 100%;
    display: flex;
    justify-content: flex-start; /* Updated */
    align-items: center;
    flex-direction: column;
`;

export const MessengerDialog = styled.div`
  height: calc(100vh - 150px);
  width: calc(100vw - 73px);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    height: 100%;
    width: 100%;
  }
  .loader {
    position: absolute;
    display: flex;
    top: 45%;
    left: 50%;
  }
  .message-box {
    height: 100%;
    overflow-y: auto;
    @media screen and (max-width: 700px) {
      row-gap: 20px;
      display: flex;
      flex-direction: column;
    }
  }
  
  }
  .send-text-input {
    img {
      &:hover {
        cursor: pointer;
      }
    }
    input {
      outline: none;
      border: none;
      width: 100%;
      height: 100%;
      font-size: 16px;
    }
    width: 100%;
    height: 7vh;
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
    padding: 10px;
  }
  .MuiBox-root {
    display: flex;
    flex-direction: column;
    padding: 10px;
    row-gap: 10px;
    overflow-y: scroll;
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${PRIMARY_COLOR};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const MessageContainer = styled.div`
    width: 100%;
    padding: 0.8rem 0;
    display: flex;
    align-items: ${(props) => props.styles.flexDirection};
    flex-direction: column;
    justify-content: flex-start; /* Changed from 'center' to 'flex-start' */
    align-items: flex-start; /* Changed from 'center' to 'flex-start' */
    .message {
        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
        padding: 5px 10px;
        border-radius: 5px;
        max-width: 50%;
        background: ${(props) => props.styles.backgroundColor};
        color: ${(props) => props.styles.fontColor};
        a {
            color: white;
        }
        @media screen and (max-width: 700px) {
            max-width: 85%;
        }
    }
`;
export const LogoContainer = styled.div`
    display: flex;
    width: 90%;
`;
