import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MessengerPage } from "./pages";
import { THEME } from "./util/Theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline enableColorScheme>
        <Router>
          <Routes>
            {/* <Route path="/chat" element={<MessengerPage />} /> */}
            <Route path="/" element={<MessengerPage />} />

          </Routes>
        </Router>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
