import React, { useState, useEffect } from "react";
import {
    MessageContainer,
    MessengerContainer,
    MessengerDialog,
    LogoContainer,
    HeaderContainer,
} from "./messenger.styles";
import { Typography } from "@mui/material";
import { GPTMessage, OWN_MESSAGE } from "../../util/constants";
import { SendChat } from "../../util/Api";
import { useRef } from "react";
import styled from "styled-components";

function MessengerPage(props) {
    const [messages, setMessages] = useState([
        "Welcome! You can now ask any questions",
    ]);

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const myDivRef = useRef(null);

    useEffect(() => {
        scrollToBottom();
    }, [myDivRef.current?.scrollHeight]);

    const scrollToBottom = () => {
        if (myDivRef.current) {
            myDivRef.current.scrollTop = myDivRef.current.scrollHeight;
        }
    };

    const handleSubmit = async () => {
        try {
            if (message === "") {
                return window.alert("Please enter a query first!");
            }
            setMessage("");
            setMessages((prevMessages) => [...prevMessages, message]);
            scrollToBottom();
            setLoading(true);
            const { data } = await SendChat({
                user_input: message,
            });
            setMessage("");
            setLoading(false);
            setMessages((prevMessages) => [...prevMessages, data.response]);
            scrollToBottom();
        } catch (err) {
            setLoading(false);
            console.log("Error sending message : ", err);
        }
    };

    const handleKeySubmit = async (event) => {
        try {
            if (event.keyCode === 13) {
                event.preventDefault();
                handleSubmit();
            }
        } catch (err) {
            setLoading(false);
            console.log("Error sending message : ", err);
        }
    };

    return (
        <MessengerContainer>
            <HeaderContainer>
                <CustomLogoContainer>
                    <img src="/assets/logo.png" alt="" />
                </CustomLogoContainer>
                <div className="title">
                    <Typography variant="h5">Personal AI Model</Typography>
                </div>
            </HeaderContainer>
            <MessengerDialog>
                {loading && (
                    <div className="loader">
                        <img src="/assets/hourglass.gif" height={40} alt="" />
                    </div>
                )}
                <div
                    className="message-box"
                    ref={myDivRef}
                    style={{ minHeight: "calc(100vh - 250px)" }}
                >
                    {messages.map((m, index) => (
                        <MessageContainer
                            styles={index % 2 !== 0 ? OWN_MESSAGE : GPTMessage}
                            style={{ flexDirection: index % 2 === 0 ? "row" : "row-reverse" }}
                            key={index}
                        >
                            <div className="message">
                                {index % 2 === 0 ? (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: m }}
                                    />
                                ) : (
                                    <Typography>{m}</Typography>
                                )}
                            </div>
                        </MessageContainer>
                    ))}
                </div>
                <div className="send-text-input">
                    <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Enter your query here.."
                        onKeyDown={handleKeySubmit}
                        disabled={loading}
                    />
                    <img
                        src="/assets/send.png"
                        height={40}
                        alt=""
                        onClick={handleSubmit}
                    />
                </div>
            </MessengerDialog>
        </MessengerContainer>
    );
}

export default MessengerPage;

const CustomLogoContainer = styled(LogoContainer)`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 44%;
    img {
        height: 200px;
    }
`;
