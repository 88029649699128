import { createTheme } from "@mui/material";
import { PRIMARY_COLOR } from "./constants";

export const THEME = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: PRIMARY_COLOR,
    },
  },
});
