export const BASE_URL = "https://personal-ai-be-wxjl.vercel.app";
export const USER = "USER";
export const FONT_COLOR = "#FEFEFE";
export const SIDE_NAV_FONT = {
    fontSize: "11px",
    fontWeight: 500,
};

export const PRIMARY_COLOR = "rgb(146, 44, 136)";
export const PRIMARY_COLOR_DARK = "#4dd8ff";
export const BACKGROUND_COLOR = "rgb(10, 25, 41)";

export const OWN_MESSAGE = Object.freeze({
    fontColor: PRIMARY_COLOR,
    backgroundColor: "#FFFFFF",
    flexDirection: "end", // Update flexDirection to "end"
});

export const GPTMessage = Object.freeze({
    fontColor: "#FFFFFF",
    backgroundColor: PRIMARY_COLOR,
    flexDirection: "start", // Update flexDirection to "start"
});

export const LinkStyle = {
    textDecoration: "none",
};
